<template>
	<div id="liveUrl">
		<div class="row">
			<div class="col" v-if="meetingDetails.meetingFormat == 'OFFLINE'">
				<div class="qrcode">
					<h4>签到二维码（扫码签到）</h4>
					<img :src="meetingDetails.signCodeUrl" alt="">
				</div>
			</div>
			<div class="col" v-else>
				<div class="qrcode">
					<h4>用户参会二维码（观看直播）</h4>
					<img :src="meetingDetails.codeUrl" alt="">
				</div>
				<div class="linkUrl">
					<h4>用户参会链接（观看直播）</h4>
					<!-- <a>{{meetingDetails.liveUrl}}</a> -->
					<a :href="meetingDetails.liveUrl" target="_blank">{{meetingDetails.liveUrl}}</a>
				</div>
			</div>
			<div class="col">
				<div class="qrcode">
					<h4>邀请二维码（参与直播）</h4>
					<img :src="meetingDetails.inviteCodeUrl" alt="">
				</div>
			</div>
		</div>
		<div class="options">
			<el-button class="bank" @click="bank()">返回首页</el-button>
			<el-button type="primary" class="next" @click="onSubmit()">查看会议详情</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getMeetingById
	} from '@/request/api.js';
	export default {
		name: 'liveUrl',
		data() {
			return {
				meetingId: '',
				meetingDetails: ''
			}
		},
		created() {
			this.meetingId = this.$route.query.mId;
			this.getMeetingDetail();
		},
		mounted() {},
		methods: {
			onSubmit() {
				this.$router.push({
					name: 'meetdetails',
					query: {
						mId: this.meetingId,
						status: 'WAIT',
						now: '3'
					}
				})
			},

			getMeetingDetail() {
				let data = {
					meetingId: this.meetingId
				}
				getMeetingById(data).then(res => {
					if (res.code == 200) {
						this.meetingDetails = res.data;
					}
				})
			},

			bank() {
				this.$router.push({
					name: 'project'
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#liveUrl {
		padding: 40px;

		.row {
			display: flex;

			.col {
				flex: 1;
			}
		}

		.qrcode,
		.linkUrl {
			margin-bottom: 30px;

			h4 {
				font-size: 22px;
				margin-bottom: 10px;
			}

			img {
				display: block;
				width: 300px;
				height: 300px;
				border-radius: 20rpx;
				box-shadow: 0 0 20px rgba(0, 0, 0, .1);
				overflow: hidden;
			}

			span {
				font-size: 14px;
			}
		}

		.options {
			display: flex;
			justify-content: center;

			.el-button {
				width: 200px;
			}
		}
	}
</style>